@import 'styles/mixins';
@import 'styles/form';

.PanelContainer {
  padding: 2rem 0 !important;
}

.TabView {
  width: 100%;

  li {
    flex-grow: 1;
  }
}
:global(.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link) {
  @extend .Body;
  @extend .Bold;
  @extend .Large;
  border-color: $color-blue-600;
}

:global(.p-tabview .p-tabview-nav li .p-tabview-nav-link) {
  @extend .Body;
  @extend .Regular;
  @extend .Large;

  color: $color-blue-400;
  border-color: $color-blue-400;
  border-bottom: 1px solid $color-blue-300;
}

:global(.p-tabview .p-tabview-nav .p-tabview-ink-bar) {
  background: $color-blue-600;
}

:global(.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus) {
  box-shadow: none;
}

.AddCard {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
  margin-bottom: 1rem;
  gap: 1px;

  width: 100%;

  > * {
    width: 100%;
  }

  border: 2px solid $color-blue-600;
}

.Field {
  width: 100%;
  margin-bottom: 1rem;
}

.Two {
  @include flex(row, flex-start);
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.CTAGroup {
  .Button {
    width: 100%;
  }
}
