@import 'styles/mixins';

.EmailBrief {
  @include flex(column, flex-start, stretch);
  width: 100%;
  height: 100%;
  position: relative;
  gap: 1.5rem;

  .Header {
    @include flex(row, flex-start);

    .Close {
      @include noStyleButton();
      @include flex();
      margin-left: auto;
      img {
        width: 2.25rem;
        height: auto;
      }
    }
  }

  .AboutCampaign,
  .AboutEvent,
  .Addons {
    @include flex(column, flex-start, stretch);
    gap: 1rem;
  }
  .Two {
    @include flex(row, space-between);
    gap: 0.5rem;
  }
}

.Panel {
  width: 30rem;
}

.CTASection {
  margin-top: auto;
  padding-bottom: 2rem;
  width: 100%;
}
