@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/form';

.Card {
  box-shadow: $box-shadow;
  border-radius: 0%;
  border: 3px solid $color-border;
  width: 100%;
  padding: 2rem 1.5rem 1rem 1.5rem;
  margin: 0 0 1rem 0;
  :global(.p-card-title) {
    @extend .Title;
    @extend .Regular;
    @extend .Small;
  }
  :global(.p-card-subtitle) {
    @extend .Body;
    @extend .Regular;
    @extend .Medium;
  }
}

.SignIn {
  @include flex();
  margin: 1rem 0 2rem;
  gap: 0.25rem;
  > span {
    @extend .Body;
    @extend .Regular;
    @extend .Medium;
  }
  .Link {
    @extend .Body;
    @extend .Bold;
    @extend .Medium;
    text-decoration: none;

    cursor: pointer;
    color: $color-orange-400;
  }
}

.Layout > :last-child {
  min-height: 100vh;
}

.Container {
  @include flex(column, flex-start);
  width: 26rem;
}
