@import 'styles/mixins';

.Header {
  @include flex(row, flex-start);
  width: 100%;
  gap: 2.8rem;
  margin-left: 2.8rem;
  img {
    height: 42px;
    width: auto;
  }
  .Text {
    @include flex(column, flex-start, flex-start);
    gap: 0.5rem;
  }
}

.CardNext {
  text-align: center;
  margin-top: 0;
  width: 640px;
  padding: 0 1rem;
  //height: 254px;
  &:global(.p-card .p-card-content) {
    padding: 0;
  }
}

.ButtonNext {
  width: 100%;
  margin: 1rem 0;
}
