@import 'styles/mixins';
@import 'styles/form';

.fc {
  .fc-view-harness {
    box-shadow: $box-shadow;
    border-radius: 0%;
    border: 3px solid $color-border;
    padding: 0;
    margin-bottom: 5rem;
  }

  .fc-toolbar-title {
    @extend .Title;
    @extend .Medium;
    @extend .Regular;
    color: $color-blue-600;
    display: inline;
  }
  .fc-col-header-cell-cushion {
    @extend .Body;
    @extend .Bold;
    @extend .Small;
    margin: 10px 0 10px 0;
    color: $color-blue-400;
    text-transform: uppercase;
  }
  &.fc-theme-standard {
    .fc-view-harness {
      th {
        background: transparent;
        border: none;
      }
      td {
        padding: 0 5px 5px 5px;
        position: relative;
        color: $color-blue-600;

        border-width: 0px 2px 2px 2px;
        border-style: solid;
        border-color: $color-blue-100;
      }
    }
  }

  .fc-daygrid-day-top {
    flex-direction: row !important;
    @extend .CalendarDay;
  }

  .fc-daygrid-day.fc-day {
    transition: all 0.3s ease;

    &:not(.fc-day-other):hover {
      background: $color-blue-100;
    }

    &.fc-day-today {
      background: $color-white;
    }
  }

  .fc-day-other .fc-daygrid-day-top {
    opacity: 1 !important;
    color: $color-blue-400;
  }

  &.fc-theme-standard {
    .fc-toolbar {
      justify-content: flex-start;
      align-items: stretch;
    }

    .fc-toolbar-chunk {
      display: flex;
      width: 20rem;
      &:last-child {
        justify-content: flex-end;
      }
    }

    .fc-toolbar .fc-button {
      color: $color-blue-600;
      background: transparent;
      border: none;
      &:enabled:hover {
        background: $color-blue-200;
      }

      &:enabled:active {
        background: $color-blue-300;
      }

      &:focus {
        box-shadow: none;
      }

      &:enabled:active:focus {
        box-shadow: none;
      }

      &.fc-shareButton-button {
        @extend .CTA;
        @include flex();
        gap: 0.5rem;
        background: $color-orange-400;
        border-radius: 0;
        min-width: 7.5rem;
        height: 100%;
        position: relative;
        color: $color-orange-600;

        &:enabled {
          &:hover {
            background: $color-orange-300;
            color: $color-orange-600;
          }
          &:active {
            background: $color-orange-400;
            color: $color-orange-600;
          }
        }

        &::after {
          content: '';
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjQwMDIgOC40QzE4Ljg5MTQgOC40IDIwLjEwMDIgNy4xOTExNyAyMC4xMDAyIDUuN0MyMC4xMDAyIDQuMjA4ODMgMTguODkxNCAzIDE3LjQwMDIgM0MxNS45MDkgMyAxNC43MDAyIDQuMjA4ODMgMTQuNzAwMiA1LjdDMTQuNzAwMiA3LjE5MTE3IDE1LjkwOSA4LjQgMTcuNDAwMiA4LjRaIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik02LjYwMDM5IDE0LjdDOC4wOTE1NiAxNC43IDkuMzAwMzkgMTMuNDkxMiA5LjMwMDM5IDEyQzkuMzAwMzkgMTAuNTA4OSA4LjA5MTU2IDkuMzAwMDUgNi42MDAzOSA5LjMwMDA1QzUuMTA5MjIgOS4zMDAwNSAzLjkwMDM5IDEwLjUwODkgMy45MDAzOSAxMkMzLjkwMDM5IDEzLjQ5MTIgNS4xMDkyMiAxNC43IDYuNjAwMzkgMTQuN1oiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE3LjQwMDIgMjEuMDAwMUMxOC44OTE0IDIxLjAwMDEgMjAuMTAwMiAxOS43OTEzIDIwLjEwMDIgMTguMzAwMUMyMC4xMDAyIDE2LjgwODkgMTguODkxNCAxNS42MDAxIDE3LjQwMDIgMTUuNjAwMUMxNS45MDkgMTUuNjAwMSAxNC43MDAyIDE2LjgwODkgMTQuNzAwMiAxOC4zMDAxQzE0LjcwMDIgMTkuNzkxMyAxNS45MDkgMjEuMDAwMSAxNy40MDAyIDIxLjAwMDFaIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik04LjkzMDY2IDEzLjM1ODlMMTUuMDc3NyAxNi45NDA5IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNS4wNjg3IDcuMDU5MDhMOC45MzA2NiAxMC42NDExIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
          background-size: cover;
          width: 20px;
          height: 20px;
        }
      }
    }
    .fc-button-group .fc-button:first-child,
    .fc-button-group .fc-button:last-child {
      border-radius: 0 !important;
    }

    .fc-button-group {
      margin-left: auto !important;
    }
  }
}

td[role='presentation'] {
  border-width: 0 0 0 0 !important;
}

.fc-event-selected:after,
.fc-event:focus:after {
  background: none !important;
}

.PromotionEvent__Campaign {
  @include campaignEvent($color-red-100, $color-red-600, $color-red-200);
}

.ProdLaunchEvent__Campaign {
  @include campaignEvent($color-green-100, $color-green-600, $color-green-300);
}

.FlowEvent__Campaign {
  @include campaignEvent($color-purple-100, $color-purple-600, $color-purple-200);
}

.OtherEvent__Campaign {
  @include campaignEvent($color-orange-100, $color-orange-600, $color-orange-200);
}

.CampaignEvent {
  @include campaignEvent($color-blue-100, $color-blue-600, $color-blue-200);
}

.Promotion__DatePicker {
  @include campaignDatePicker($color-red-200, $color-red-100);
}
.Flow__DatePicker {
  @include campaignDatePicker($color-purple-200, $color-purple-100);
}
.ProdLaunch__DatePicker {
  @include campaignDatePicker($color-green-300, $color-green-100);
}
.Other__DatePicker {
  @include campaignDatePicker($color-blue-200, $color-blue-100);
}
