@import 'styles/mixins';
@import 'styles/variables';

.Layout {
  @include font();
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: block;
  height: max-content;
  z-index: 2;
  overflow-x: hidden;
  .Page {
    @include flex(column, center, center);
    position: relative;
  }
}
