@import 'styles/mixins';
@import 'styles/form';

.EmailDesignExample {
  @include flex();
  border: 1px solid $color-blue-200;
  padding: 0.5rem 0.25rem;
  position: relative;
  height: 100%;

  .Preview {
    @include flex();
    width: 100%;
    .Image {
      border: 1px solid $color-blue-600;
      max-height: 8rem;
      height: auto;
      object-fit: cover;
      max-width: 95%;
    }
  }

  .ViewImage {
    @include flex();
    @include noStyleButton();
    transition: all 0.25s ease;
    cursor: pointer;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    background: $color-blue-200;
    width: 2rem;
    height: 1.75rem;
    img {
      @include applyFilter($color-blue-600);
    }

    &:hover {
      background: $color-blue-500;
    }
  }

  .Index {
    @include flex();
    @extend .CTA;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    background: $color-blue-600;
    color: $color-blue-100;
    width: 1.5rem;
    height: 1.5rem;
    &:hover {
      font-style: normal;
    }
  }
}
