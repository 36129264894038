@import 'styles/mixins';

.Dialog {
  &__Footer {
    @include flex();
  }
  &__Buttons {
    @include flex(row);
    width: 100%;
    gap: 1rem;
  }
  &__Button {
    width: fit-content !important;
  }
}
