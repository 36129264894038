@import 'styles/mixins';

.TipBanner {
  @include flex(row, flex-start);
  height: 3.5rem;
  align-items: stretch;

  .Logo {
    @include flex();
    width: 3.25rem;
    background: $color-blue-600;
    img {
      @include applyFilter($color-blue-100);
      width: 1.25rem;
      height: auto;
    }
  }
  .Content {
    @include flex(row, space-between, center);
    border: 2px solid $color-blue-200;
    flex-grow: 1;
    padding-left: 1rem;

    .Close {
      @include flex();
      @include noStyleButton();
      height: 100%;
      padding: 0 1rem;
    }
  }
}
