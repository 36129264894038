@import 'styles/mixins';

.MultipleChoice {
  @include flex(row, flex-start, center);
  box-sizing: border-box;
  border: 2px solid $color-blue-100;
  gap: 0.5rem;
  height: 4rem;
  width: 100%;
  padding: 0 0.875rem 0 0.875rem;
  transition: 0.3s all ease;

  &:hover {
    border: 2px solid $color-blue-200;
  }

  &.Active {
    background: $color-blue-100;
    border: 2px solid $color-blue-600;
  }

  &.Disabled {
    border: 2px solid $color-grey-400;
    background: $color-grey-200;
    > span {
      color: $color-grey-600;
    }
    .Choice {
      cursor: default;
      label {
        border: 1px solid $color-grey-400;
        cursor: default;
      }
      div {
        color: $color-grey-600;
      }
      .Check {
        background: $color-grey-600;
      }
    }
  }

  .Choice {
    @include flex(column);
    @include noStyleButton();
    outline: none;
    gap: 0.2rem;
    label {
      cursor: pointer;
      position: relative;
      width: 0.875rem;
      height: 0.875rem;
      border: 1px solid $color-blue-300;
      border-radius: 50%;

      input {
        display: none;
      }

      .Check {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-color: $color-blue-600;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
      }
    }
  }
}
