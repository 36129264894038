@import 'styles/variables';
@import 'styles/form';

.Tooltip {
  max-width: 15rem;
  white-space: pre-wrap;
  &__Icon {
    height: 0.875rem;
    width: 0.875rem;
  }

  :global(.p-tooltip-text) {
    @extend .Body;
    @extend .Regular;
    @extend .Small;
    color: $color-white;
  }
}
