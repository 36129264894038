@import 'styles/mixins';

.Menubar {
  position: absolute;
  width: 100%;
  background-color: transparent;
  color: $color-blue-600;
  padding: 1rem 3.6rem;
  border: none;
  z-index: 3;
  align-items: center;
  ul {
    @include font();
    margin-left: auto;
    text-transform: capitalize;
    font-weight: bold;
  }
  &.light {
    color: $color-blue-100;
  }
  &.dark {
    color: $color-blue-600;
  }
  & > a[class='p-menubar-button'] {
    display: none;
  }
  & > ul[class='p-menubar-root-list'] {
    display: inline-flex;
    width: unset;
    position: relative;
    background-color: transparent;
    @media screen and (max-width: 960px) {
      box-shadow: none;
    }
  }
}

.AuthItem {
  @include noStyleButton();
  width: 100%;
  text-align: left;
  user-select: none;
  background: $color-white;
  transition: all ease-out 0.5s;
  padding: 1rem;
  &:hover {
    background: $color-blue-100;
    color: $color-blue-400;
  }
}

:global(.p-menubar .p-submenu-list) {
  border: 2px solid $color-blue-600;
  right: 0;
  padding: 0;
}
