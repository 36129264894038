@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/form';

.MultiSelectContainer {
  position: relative;

  .MultiSelect {
    @include font('Lato-Bold');
    align-self: stretch;
    border-radius: 0;
    border: 2px solid $color-blue-600;
    font-size: 14px;
    line-height: 17px;
    color: $color-blue-500;
    background: transparent;
    font-weight: 800;
    height: 3rem;
    width: 56%;
    box-shadow: none;

    &::placeholder {
      @include font('Lato');
      color: $color-blue-400;
      font-weight: 400;
    }

    &:global(.p-multiselect:not(.p-disabled).p-focus) {
      box-shadow: none;
    }
  }

  label {
    @include font('Lato-Bold');
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    position: absolute;
    color: $color-blue-600;
    top: -1.125rem;
    left: 0.875rem;
  }

  &.Error {
    .MultiSelect {
      border: 2px solid $color-red-500;
    }
    label {
      color: $color-red-500;
    }
  }

  &.Filled {
    background: $color-blue-100;
  }

  &.Disabled {
    background: $color-blue-200;
    border: 1px solid $color-blue-400;

    label {
      color: $color-blue-400;
    }
  }

  &.Special {
    .MultiSelect {
      border: solid $color-blue-400;
      border-width: $border-underline;
      padding: 0.5rem;
    }
    label {
      display: none;
    }

    &.Filled .MultiSelect {
      border: solid $color-blue-600;
      border-width: $border-underline;
    }

    &.Error .MultiSelect {
      border: solid $color-red-500;
      border-width: $border-underline;
      &::placeholder {
        color: $color-red-500;
      }
    }
  }
}

:global(.p-multiselect .p-multiselect-label) {
  padding-top: 1rem;
  height: 100%;
  &:global(.p-placeholder) {
    color: $color-blue-400;
    font-weight: 400;
  }
}

:global(.p-multiselect-panel .p-multiselect-items .p-multiselect-item) {
  @extend .Body;
  @extend .Bold;
  @extend .Medium;
  color: $color-blue-600;
  text-transform: capitalize;
  &:global(.p-highlight) {
    background-color: $color-blue-100;
    color: $color-blue-500;
  }
  :global(.p-checkbox .p-checkbox-box) {
    border-radius: 0;
  }
}
