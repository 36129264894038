@import 'styles/mixins';

.CompanySettings {
  @include flex(column, flex-start);
  width: 100%;
  color: $color-blue-100;
  padding: 5rem 3.5rem 3.5rem 3.5rem;
  height: 100vh;
}

.Header {
  @include flex(row, flex-start);
  width: 100%;
  gap: 2.8rem;
  margin-left: 2.8rem;
  img {
    height: 42px;
    width: auto;
  }
  .Text {
    @include flex(column, flex-start, flex-start);
    gap: 0.5rem;
  }
}

.UpdateButton {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 4rem;
  z-index: 990;
  pointer-events: all;
}
