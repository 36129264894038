@import 'styles/variables';

@font-face {
  font-family: $font-family;
  src: local('Merriweather'), url('/assets/fonts/Merriweather-Regular.ttf') format('truetype'),
    local('Merryweather-Bold'), url('/assets/fonts/Merriweather-Bold.ttf') format('truetype'),
    local('Merryweather-Italic'), url('/assets/fonts/Merriweather-Italic.ttf') format('truetype');
}

@font-face {
  font-family: $font-subtitle;
  src: url('/assets/fonts/Lato-Regular.ttf') format('truetype'),
    url('/assets/fonts/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato-Bold'), url('/assets/fonts/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'MADEAvenue';
  src: local('MADEAvenue'), url('/assets/fonts/MADEAvenue.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url('/assets/fonts/HelveticaNeue.ttf') format('truetype');
}
