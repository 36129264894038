@import 'styles/mixins';
@import 'styles/form';

.CampaignDetails {
  @include calendarEventColoring();
  width: 100%;
  margin-bottom: 2.5rem;

  .Title {
    @include flex(row, flex-start);
    @extend .Body;
    @extend .Bold;
    @extend .Medium;
    width: 100%;
    min-height: 2rem;
    padding: 0.5rem 0.75rem;
  }

  .Details {
    padding-left: 0.5rem;
    border-left: 2px solid $color-blue-200;
    .Header {
      @include flex(row, flex-start);
      :first-child,
      :last-child {
        flex-shrink: 0;
      }
      :last-child {
        margin-left: auto;
      }

      .CampaignTitle {
        @extend .Body;
        @extend .Large;
        @extend .Bold;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
        text-align: left;
        overflow: auto;

        &:not(.extended) {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .DeleteCampaign {
        @include noStyleButton();
        width: 1.25rem;
        height: 1.25rem;
        img {
          @include applyFilter($color-blue-600);
          width: 100%;
          height: auto;
        }
        &:disabled {
          img {
            @include applyFilter($color-grey-400);
          }
        }
      }
    }

    .DateContainer {
      margin: 0.5rem 0 1rem;
      position: relative;
      button {
        @include noStyleButton();
        @include flex(row, flex-start);
        display: inline-flex;
        gap: 0.25rem;
        margin-left: 0.5rem;
        img {
          @include applyFilter($color-orange-400);
          width: 1rem;
          height: auto;
        }
      }

      .ChangeDate {
        position: absolute;
        width: 100%;
        z-index: 10;
      }
    }
  }
}
