@import 'styles/mixins';

.Card {
  width: 100%;
  text-align: left;
  padding: 0 1.25rem;
  margin-bottom: 2.2rem;
  .Title {
    padding-bottom: 2rem;
  }
}

.CheckboxList {
  @include flex(row, flex-start);
  gap: 0.5rem;
  width: 100%;
  flex-wrap: wrap;
  .Checkbox {
    flex: 0 1 auto;
    user-select: none;
    flex-grow: 1;
  }
}

.Offerings {
  @include flex();
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
}

.Catalogs {
  @include flex(row, center, stretch);
  gap: 2rem;
  width: 100%;

  > * {
    flex: 1;
  }
}
