@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';
@import 'react-toastify/dist/ReactToastify.css';
@import 'styles/variables';
@import 'styles/form';
@import 'styles/mixins';
@import 'styles/steps';
@import 'styles/calendar';

:root {
  --text-color: $color-blue-600;
}
