@import 'styles/mixins';

.Dialog {
  border: 3px solid $color-blue-600;
  border-radius: 0;
  position: relative;

  &__Header {
    border-radius: 0 !important;
    padding: 1.5rem 2.3rem !important;

    :global(.p-dialog-header-icons) {
      position: absolute;
      top: 0;
      right: -2.5rem;
      button {
        &:hover {
          background: none !important;
        }
        &:focus {
          box-shadow: none !important;
        }
      }
    }
    :global(.p-dialog-header-close-icon) {
      filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(156deg) brightness(108%)
        contrast(101%);
    }
  }

  &__Content {
    padding-bottom: 1rem !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  :global(.p-dialog-footer) {
    border-radius: 0;
  }
}
