@import 'styles/mixins';
@import 'styles/form';

.EventDetails {
  width: 100%;
  @include calendarEventColoring();

  .Title {
    @include flex(row, space-between);
    @extend .Body;
    @extend .Bold;
    @extend .Medium;
    width: 100%;
    min-height: 2rem;
    padding: 0.5rem 0.75rem;
  }
  .Delete {
    @include noStyleButton();
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    img {
      @include applyFilter($color-blue-600);
      width: 100%;
      height: auto;
    }
  }

  .Details {
    padding-left: 0.5rem;
    border-left: 2px solid $color-blue-200;
  }
}
