@import 'styles/variables';
@import 'styles/fonts';

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-blue-100;
}

code {
  font-family: $font-family;
}
