@import 'styles/mixins';

.Container {
  @include flex(row, flex-start);
  gap: 0.5rem;
  margin-bottom: 1rem;
  user-select: none;
  cursor: pointer;
  &.error {
    span {
      color: $color-red-500;
    }
  }
}
