@import 'styles/mixins';
@import 'styles/animations';

.ShowContent {
  @include noStyleButton();
  @include flex(row);
  margin-bottom: 0.5rem;

  .ShowIcon {
    @include applyFilter($color-blue-500);
    transition: 0.25s all ease;

    &.Open {
      transform: rotate(-180deg);
    }
  }
}

.Content {
  animation: fadeIn 0.75s;
}
