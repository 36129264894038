@import 'styles/variables';
@import 'styles/mixins';

.GoogleButton {
  all: unset;

  @include flex();
  @include font();
  text-transform: uppercase;
  font-weight: 900;
  font-size: 0.875rem;
  letter-spacing: 0.125rem;
  line-height: 1.125rem;
  width: 100%;
  gap: 0.5rem;
  cursor: pointer;
  padding: 1rem;
  background-color: $color-blue-600;
  color: $color-blue-100;
  margin: 0;
  padding: 1rem 0;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: $color-blue-500;
    font-style: italic;
  }
  &:disabled {
    background-color: $color-grey;
    &:hover {
      font-style: normal;
    }
  }
}
