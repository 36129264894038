@import 'styles/variables';
@import 'styles/mixins';

.Welcome {
  width: 100%;
  min-height: 100vh;
  .Background {
    @include pageBackground(38.5rem);
  }
  &__Container {
    @include flex(column, flex-start);
    color: $color-blue-100;
    padding-top: 5.5rem;

    h1 {
      font-weight: 400;
      line-height: 3rem;
      font-size: 2.25rem;
      margin-bottom: 1rem;
    }
    h4 {
      color: $color-blue-100;
      text-align: center;
      width: 37.5rem;
      margin-top: 0;
    }
    .Steps {
      margin: 1.5rem 0 3.75rem;
      width: 94%;
      &:global(.p-steps .p-steps-item .p-menuitem-link .p-steps-title) {
        line-height: 24px;
      }
    }
  }
}
