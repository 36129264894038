@import 'styles/mixins';
.EmailModule {
  transition: 0.5s all ease;
  @include flex(row, space-between, flex-start);
  width: 100%;

  &.open {
    width: 100%;
    border: 2px solid $color-blue-300;
    padding: 2.5rem;
    gap: 1.5rem;
  }
  &:not(.open) .Module {
    margin: 0 auto;
  }

  .Module {
    @include noStyleButton();
    position: relative;
    width: 50%;
    height: fit-content;
    cursor: pointer;
    flex-shrink: 0;

    img {
      user-select: none;
      position: relative;
      &:not(.open) {
        transition: 0.1s all ease;
      }
      width: 100%;
      height: auto;
      top: 0;
      left: 0;

      &:hover,
      &.open {
        border: 2px solid $color-blue-600;
        filter: drop-shadow(10px 10px 0px $color-blue-300);
        top: -10px;
        left: -10px;
      }
    }
  }

  .ModuleInfo {
    @include flex(column, flex-start, stretch);
    gap: 0.5rem;
    flex-grow: 1;

    .Header {
      @include flex(row, flex-start);
      flex-grow: 1;
      gap: 0.5rem;

      :first-child {
        flex-grow: 1;
      }

      .Delete,
      .Close {
        @include flex();
        @include noStyleButton();

        img {
          width: 2rem;
          height: auto;
        }
      }
    }

    .Properties {
      @include flex(row, flex-start);
      margin-bottom: 2rem;
      gap: 0.5rem;

      img {
        @include applyFilter($color-blue-500);
      }
    }

    .Listing {
      @include flex(row, flex-start, stretch);
      gap: 0.5rem;
      flex-wrap: wrap;

      .Item {
        width: calc(33% - 0.5rem);
      }
    }
  }
}

.Separator {
  border: 1px solid $color-blue-200;
  width: 100%;
  margin: 0.25rem 0;
}

.Spinner {
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
