@import 'styles/mixins';

.ExpandItem {
  @include flex(row, flex-start);
  border: 2px solid $color-blue-600;
  height: 3rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  :first-child {
    @include applyFilter($color-blue-600);
    margin-right: auto;
  }
  > * {
    flex-shrink: 0;
  }
}

.Btn {
  @include noStyleButton();
  transition: all 0.2s ease-in;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  &:hover {
    background: $color-orange-200;
  }
}
