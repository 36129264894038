@import 'styles/variables';
@import 'styles/mixins';

.PasswordContainer {
  position: relative;
  width: 50%;
  .Password {
    @include font('Lato-Bold');
    max-width: 100%;
    align-self: stretch;
    border-radius: 0;
    border: 2px solid $color-blue-600;
    font-size: 14px;
    line-height: 17px;
    padding-top: 2rem;
    color: $color-blue-500;
    background: transparent;
    font-weight: 800;

    &::placeholder {
      @include font('Lato');
      color: $color-blue-400;
      font-weight: 400;
    }
    &:focus {
      box-shadow: none;
    }
  }

  label {
    @include font('Lato-Bold');
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    position: absolute;
    color: $color-blue-600;
    top: 0.875rem;
    left: 0.875rem;
  }

  &.Error {
    .Password {
      border: 2px solid $color-red-500;
      color: $color-red-500;
    }
  }

  &.Filled {
    background: $color-blue-100;
  }

  &.Disabled {
    background: $color-grey-200;
    .Password {
      border: 2px solid $color-grey-400;
      opacity: 1;
      color: $color-grey-500;
    }
  }

  &.Special {
    .Password {
      border: solid $color-blue-400;
      border-width: $border-underline;
      padding: 0.5rem;
    }
    label {
      display: none;
    }

    &.Filled .Password {
      border: solid $color-blue-600;
      border-width: $border-underline;
    }

    &.Error .Password {
      border: solid $color-red-500;
      border-width: $border-underline;
      &::placeholder {
        color: $color-red-500;
      }
    }
  }
}
