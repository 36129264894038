@import 'styles/mixins';
@import 'styles/variables';

.centered {
  @include flex();
  width: 100%;
  height: 100%;
}
.custom-spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite;
  stroke: $color-blue-500;
  stroke-dasharray: 60, 200;
}
