@import 'styles/mixins';

.MainCalendar {
  @include flex(row, flex-start, flex-start);
  width: 100%;
  gap: 2rem;

  .Calendar {
    width: 75%;
  }
  .SideColumn {
    width: 330px;
    flex-shrink: 0;
    @include flex(column, flex-start, flex-start);
    .MonthlySettings {
      margin: 0;
      width: 100%;

      h1 {
        color: $color-blue-300;
      }

      .MonthlySummary {
        @include flex(row, space-between);
        flex-wrap: wrap;
        row-gap: 0.5rem;
        column-gap: 1rem;
        width: 80%;
        margin: 0 auto 1rem;

        .Reference {
          flex: 40% 0;
          @include flex(row, flex-start);

          gap: 0.5rem;
          > div {
            flex-shrink: 0;
          }
          :first-child {
            width: 0.75rem;
            height: 0.75rem;
          }
        }
      }
    }

    h1 {
      margin-top: 2rem;
    }

    .Placeholder {
      width: 100%;
      height: 400px;
    }

    .SwapOptions {
      margin-top: 2.5rem;
    }
  }
}

.CalendarView {
  color: $color-blue-300;
}

.DayHeader {
  color: $color-blue-400;
}
