@import 'styles/variables';
@import 'styles/mixins';

.MenuButton {
  all: unset;
  cursor: pointer;
  @include flex();
  gap: 0.5rem;
  border-radius: 0%;
  padding: 0.6rem 1rem;
  user-select: none;
  transition: all 0.2s ease;
  text-transform: uppercase;
  &.Login,
  &.Signup {
    color: $color-blue-100;
    font-weight: 900;
    font-size: 0.875rem;
  }
  &.Login {
    background-color: $color-blue-200;
    margin-right: 0.75rem;
    span {
      color: $color-blue-600;
    }
  }

  &.Signup {
    background-color: $color-blue-600;
    span {
      color: $color-blue-100;
    }
  }
  &.User {
    @include font($font-subtitle);
    text-transform: capitalize;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    &.light {
      span,
      i {
        color: $color-blue-600;
      }
      img {
        @include applyFilter($color-blue-600);
      }
    }
    &.dark {
      span,
      i {
        color: $color-blue-100;
      }
      img {
        @include applyFilter($color-blue-100);
      }
    }
  }
  &.btnHover {
    transition: all 0.25 ease-out;
    &:hover {
      opacity: 0.7;
    }
  }
}
