@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/form';

$toggle-sliding: 8px;

.ToggleQuestion {
  @include flex(row, flex-start);
  box-sizing: border-box;
  border: 2px solid $color-blue-100;
  height: 4rem;
  flex-grow: 1;
  padding: 0.875rem;
  transition: 0.3s all ease;

  &:hover {
    border: 2px solid $color-blue-200;
  }

  &.Active {
    background: $color-blue-100;
    border: 2px solid $color-blue-600;
  }

  &.Disabled {
    background: $color-grey-200;
    border: 2px solid $color-grey-400;
    > span {
      color: $color-grey-600;
    }
    .Switch .Slider {
      cursor: default;
      background-color: $color-grey-500 !important;
    }
  }

  .Switch {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 18px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .Slider {
        background-color: $color-blue-600;
      }

      &:focus + .Slider {
        box-shadow: 0 0 1px $color-blue-600;
      }

      &:checked + .Slider:before {
        -webkit-transform: translateX($toggle-sliding);
        -ms-transform: translateX($toggle-sliding);
        transform: translateX($toggle-sliding);
      }
    }

    .Slider {
      @include noStyleButton();
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color-blue-300;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &:before {
        position: absolute;
        content: '';
        height: 14px;
        width: 14px;
        left: 2px;
        bottom: 2px;
        background-color: $color-white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      &.Round {
        border-radius: 34px;

        &:before {
          border-radius: 50%;
        }
      }
    }

    &__Container {
      @include flex();
      box-sizing: border-box;
      gap: 0.125rem;
      > span {
        text-align: center;
        width: 20px;
      }
    }
  }
}

.TextInactive {
  @extend .Regular;
  color: $color-blue-300;
}
