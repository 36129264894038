@import 'styles/mixins';

.EmailLayout {
  width: 100%;
  min-height: 100vh;

  &__Container {
    @include flex(row, flex-start, flex-start);
    color: $color-blue-600;
    padding: 7.5rem 3.5rem 3.5rem 3.5rem;
    width: 100%;
    min-height: 100vh;
    gap: 1.5rem;
  }
}
