@import 'styles/variables';
@import 'styles/mixins';

.p-steps {
  .p-steps-item {
    opacity: 1;
    width: 25%;

    &:before {
      content: ' ';
      border-top: 3px solid $color-blue-500;
      width: 100%;
      top: 30%;
      left: 0;
      display: block;
      position: absolute;

      @media screen and (max-width: 1100px) {
        top: 20%;
      }
    }

    &:first-child:before {
      width: 50%;
      left: 50%;
    }
    &:last-child:before {
      width: 50%;
    }

    &.p-steps-current ~ .p-steps-item .p-menuitem-link .p-steps-number {
      color: $color-blue-400;
      background: $color-blue-600;
      border: 3px solid $color-blue-400;
    }

    .p-menuitem-link {
      background: transparent;
      &:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
      }

      .p-steps-number {
        @include font();
        background: $color-blue-100;
        color: $color-blue-600;
        border: none;
        border-radius: 0;
        width: 40px;
        height: 38px;
        line-height: 2rem;
        z-index: 1;
        font-weight: 900;
        font-size: 0.875rem;
        line-height: 18px;
      }

      .p-steps-title {
        @include font('Lato-Bold');
        color: $color-blue-100;
        overflow-wrap: break-word;
        text-align: center;
        white-space: pre-wrap;
        font-weight: 800;
        font-size: 20px;
        line-height: 19px;
        margin-top: 1.875rem;
      }
    }

    &.p-highlight .p-steps-number {
      background: $color-orange-400;
      color: $color-orange-600;
      border: none;
    }
  }
}
