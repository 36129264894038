@import 'styles/mixins';

.GuideText {
  @include flex(column, flex-start);
  gap: 1rem;
  img {
    @include applyFilter($color-blue-200);
  }
  div {
    width: 90%;
    text-align: center;
  }
}
