@import 'styles/variables';
@import 'styles/mixins';

.Button {
  @include font();
  @include flex();
  box-shadow: none;
  border-radius: 0;
  border: 0;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.125rem;
  user-select: none;

  .Content {
    @include flex();
    transform: scale(1);
    gap: 0.8125rem;
    img {
      height: 18px;
      width: auto;
    }
  }

  &:hover .Content {
    transform: scale(0.98);
  }

  &:hover,
  &:active {
    font-style: italic;
  }

  &:enabled:active,
  &:not(:active) {
    box-shadow: none;
  }

  &.Large {
    width: 13.5rem;
    height: 3.75rem;
  }

  &.Small {
    width: 11.625rem;
    height: 2.5rem;
  }

  &.Dark {
    &.Primary {
      background: $color-blue-600;
    }

    &.Secondary {
      background: $color-blue-200;
      color: $color-blue-600;
    }

    &:hover {
      background: $color-blue-500;
    }

    &.Primary,
    &:hover,
    &:active {
      color: $color-blue-100;
    }

    &:active {
      background: $color-blue-600;
    }
  }

  &.Light {
    &.Primary {
      background: $color-blue-100;
    }

    &.Secondary {
      background: $color-blue-500;
      color: $color-blue-100;
    }

    &:hover {
      background: $color-blue-200;
    }

    &.Primary,
    &:hover,
    &:active {
      color: $color-blue-600;
    }

    &:active {
      background: $color-blue-100;
    }
  }

  &.Orange {
    background: $color-orange-400;
    color: $color-orange-600;

    &:hover {
      background: $color-orange-300;
    }

    &:active {
      background: $color-orange-400;
    }

    &:active,
    &:hover {
      color: $color-orange-600;
    }
  }

  .Icon {
    max-width: 20px;
    max-height: 20px;
  }

  &.Disabled {
    background: $color-grey-200 !important ;
    opacity: 1;
    color: $color-grey-600;

    .Icon {
      @include applyFilter($color-grey-600);
    }

    &:hover {
      background-color: $color-grey-200;
      color: $color-grey-600;
      font-style: normal;
      .Content {
        transform: scale(1);
      }
    }
  }
}
