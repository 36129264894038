@import 'primeflex/src/variables';

/* Colors */
$color-white: #ffffff;
$color-black: #000000;
$color-grey: grey;
$color-grey-100: #f3f4f6;
$color-grey-200: #e5e7eb;
$color-grey-300: #e0e0e0;
$color-grey-400: #bdbdbd;
$color-grey-500: #9e9e9e;
$color-grey-600: #757575;
$color-blue-100: #f0f1f9;
$color-blue-200: #c5c9e8;
$color-blue-300: #99a0d6;
$color-blue-400: #6d77c5;
$color-blue-500: #404ba0;
$color-blue-600: #171b3a;
$color-orange-100: #fdf3ed;
$color-orange-200: #f7cdb6;
$color-orange-300: #f1a67e;
$color-orange-400: #eb8147;
$color-orange-500: #ca5516;
$color-orange-600: #491f08;
$color-red-100: #fbefef;
$color-red-200: #efbebe;
$color-red-300: #e28d8d;
$color-red-500: #c33232;
$color-red-600: #401111;
$color-dark-red: #98473e;
$color-border: #171b3a;
$color-green-100: #f4f8f2;
$color-green-300: #b1cca3;
$color-green-500: #659150;
$color-green-600: #131b0e;
$color-purple-100: #f4eefb;
$color-purple-200: #d5bdef;
$color-purple-500: #6428a4;
$color-purple-600: #1e0c31;

/* Fonts */
$font-size: 14px;
$font-size-error: 11px;
$font-size-button: 18px;
$font-family: 'Merriweather';
$font-subtitle: 'Lato';
$font-donut-amount: 3.125rem $font-family;
$font-donut-unit: 1rem 'Lato-Bold';

/* Card */
$box-shadow: 10px 10px 0px #99a0d6;

/* Text field */
$border-underline: 0 0 2px 0;
