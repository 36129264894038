@import 'styles/mixins';

.FileUpload {
  padding: 1rem;
  border: 3px dashed $color-blue-200;

  .Buttons {
    @include flex(row, flex-end);
    gap: 1rem;
  }
}
