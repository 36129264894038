@import 'styles/variables';

@mixin flex($direction: row, $justifyContent: center, $alignItems: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin font($fam: $font-family) {
  font-family: $fam;
}

@mixin pageBackground($height, $color: $color-blue-600) {
  background-color: $color;
  width: 100%;
  height: $height;
  position: absolute;
  z-index: -1;
}

@mixin campaignEvent($hoverBackground, $hoverColor, $focusBackground) {
  cursor: pointer;
  user-select: none;
  margin-bottom: 2px;

  &.Event {
    padding-left: 0.2rem;
  }

  &:not(.Event, .selected):hover {
    background: $hoverBackground !important;
    color: $hoverColor !important;
    .fc-event-title {
      text-decoration-line: underline;
    }
  }

  &.selected {
    box-sizing: border-box;
    background: $focusBackground !important;
    color: $hoverColor !important;
    outline: 2px solid $hoverColor;
    display: '' !important;
    border-radius: 3px;
    background-color: transparent;
  }
}

@mixin noStyleButton() {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@mixin applyFilter($selectedColor) {
  filter: brightness(0) saturate(100%)
    map-get(
      (
        $color-orange-400: invert(55%) sepia(87%) saturate(538%) hue-rotate(330deg) brightness(98%)
          contrast(88%),
        $color-orange-600: invert(15%) sepia(62%) saturate(736%) hue-rotate(339deg) brightness(94%)
          contrast(102%),
        $color-blue-100: invert(90%) sepia(3%) saturate(562%) hue-rotate(198deg) brightness(107%)
          contrast(95%),
        $color-blue-200: invert(80%) sepia(8%) saturate(835%) hue-rotate(196deg) brightness(106%)
          contrast(82%),
        $color-blue-400: invert(42%) sepia(74%) saturate(353%) hue-rotate(196deg) brightness(99%)
          contrast(90%),
        $color-blue-500: invert(29%) sepia(45%) saturate(856%) hue-rotate(196deg) brightness(95%)
          contrast(98%),
        $color-blue-600: invert(11%) sepia(7%) saturate(5431%) hue-rotate(197deg) brightness(93%)
          contrast(98%),
        $color-red-500: invert(42%) sepia(52%) saturate(2170%) hue-rotate(333deg) brightness(75%)
          contrast(105%),
        $color-black: invert(0%) sepia(82%) saturate(7500%) hue-rotate(14deg) brightness(113%)
          contrast(116%),
        $color-grey-600: invert(47%) sepia(0%) saturate(0%) hue-rotate(224deg) brightness(91%)
          contrast(57%),
        $color-grey-300: invert(100%) sepia(0%) saturate(1469%) hue-rotate(233deg) brightness(111%)
          contrast(76%),
        $color-grey-400: invert(95%) sepia(0%) saturate(1%) hue-rotate(147deg) brightness(82%)
          contrast(85%),
      ),
      $selectedColor
    );
}

@mixin calendarEventColoring() {
  .Dot {
    border-radius: 50%;
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: red;
  }

  &.promotion {
    .Title {
      color: $color-red-600;
      background: $color-red-200;
    }
    .Dot {
      background-color: $color-red-500;
    }
  }
  &.flow {
    .Title {
      color: $color-purple-600;
      background: $color-purple-200;
    }
    .Dot {
      background-color: $color-purple-500;
    }
  }
  &.product_launch {
    .Title {
      color: $color-green-600;
      background: $color-green-300;
    }
    .Dot {
      background-color: $color-green-500;
    }
  }
  &.campaign {
    .Dot {
      background-color: $color-blue-500;
    }
  }
  &.other {
    .Title {
      color: $color-orange-600;
      background: $color-orange-200;
    }
    .Dot {
      background-color: $color-orange-500;
    }
  }
}

@mixin campaignDatePicker($background, $hoverBackground) {
  .p-datepicker:not(.p-disabled) table td span {
    color: $color-blue-600;
    &:not(.p-highlight):not(.p-disabled) {
      background: $background;
      border-radius: 0;
      &:hover {
        background: $hoverBackground;
      }
    }
    &.p-highlight {
      background: $background;
      border-radius: 0;
      &:hover {
        background: $hoverBackground;
      }
    }
  }
}
