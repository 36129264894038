@import 'styles/mixins';

.Logo {
  @include flex();
  @include font();
  font-weight: 900;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
  &__Icon {
    height: 2.25rem;
    padding-right: 0.5rem;
    width: auto;
  }
  &.dark {
    color: $color-blue-100;
  }
  &.light {
    color: $color-blue-600;
  }
}

.Link {
  all: unset;
}
