@import 'styles/mixins';

.EmailBuilder {
  @include calendarEventColoring();
  width: 100%;
  height: fit-content;
  .Header {
    @include flex(row, flex-start);
    gap: 2.5rem;
    padding-left: 1rem;
    margin-bottom: 3rem;

    .Back {
      @include noStyleButton();
      img {
        width: 3.75rem;
        height: auto;
      }
    }

    .CampaignInfo {
      @include flex(column, flex-start, flex-start);
      flex-grow: 1;
      gap: 1rem;
      align-items: stretch;
      .Parent {
        height: 2rem;
        line-height: 2rem;
        vertical-align: middle;
        padding-left: 0.75rem;
      }
      .TitleSection {
        @include flex(row, flex-start);
        gap: 1rem;
        .Dot {
          width: 1rem;
          height: 1rem;
          flex-shrink: 0;
        }
      }
    }
  }

  .Modules {
    @include flex(column, flex-start);
    width: 100%;
    margin-top: 2.25rem;
    gap: 1rem;
  }
}
