@import 'styles/mixins';

.SliderContainer {
  @include flex(row);
  position: relative;
  margin: 1rem 0;

  .SliderNotches {
    margin: 0 1rem;
    flex-grow: 1;
    .Slider {
      flex-grow: 1;
      background: $color-blue-300;
      margin: 0.5rem 0;

      :global(.p-slider-range) {
        background: $color-blue-500;
      }
      :global(.p-slider-handle) {
        border: 2px solid $color-blue-600;
        &:hover {
          border: 2px solid $color-blue-600;
          background: $color-white;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
    .Notches {
      @include flex(row, space-between);
      .Notch {
        content: '';
        flex-grow: 1;
        border-left: 1px solid $color-blue-200;
        height: 0.25rem;
        &:last-child {
          border-right: 1px solid $color-blue-200;
        }
      }
    }
  }
}
