@import 'styles/mixins';

.Zoomable {
  @include noStyleButton();
  @include flex(column);
  width: 70%;
  position: relative;
  overflow: auto;
  cursor: zoom-in;
  overflow: hidden;
  .Images {
    @include flex(column, flex-start);
    width: 100%;
    height: auto;
    img {
      max-width: 100%;
    }
  }
}

.ImageViewer {
  @include flex(row, center, stretch);
  gap: 0.5rem;
  padding: 4rem;
  &:not(.zoomed) {
    padding: 2rem;
    .Zoomable {
      width: 15%;
      &.OneImage {
        width: auto;
        height: calc(100vh - 4rem);
        overflow: hidden;
        .Images {
          height: 100%;
          img {
            height: 100%;
            width: auto;
          }
        }
      }
    }
  }

  &__Menu {
    @include flex(column, flex-start, center);
    gap: 0.5rem;
    .Button {
      @include noStyleButton();
      img {
        @include applyFilter($color-blue-100);
        width: 2rem;
        height: auto;
      }
    }
  }
}
