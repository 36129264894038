@import 'styles/mixins';

.EmailAlternative {
  @include flex(column);
  border: 1px solid $color-blue-200;
  padding: 0.5rem;
  position: relative;
  height: 100%;
  gap: 0.5rem;

  .Preview {
    @include flex();
    width: 100%;
    flex-grow: 1;
    .Image {
      border: 1px solid $color-blue-600;
      max-height: 8rem;
      height: auto;
      object-fit: cover;
      max-width: 95%;
    }
  }

  .SwapButton {
    width: 100%;
    height: 2rem;
  }

  .ViewImage {
    @include flex();
    @include noStyleButton();
    transition: all 0.25s ease;
    cursor: pointer;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    background: $color-blue-200;
    width: 2rem;
    height: 1.75rem;
    img {
      @include applyFilter($color-blue-600);
    }

    &:hover {
      background: $color-blue-500;
    }
  }
}
