@import 'styles/mixins';

.NotFound {
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid $color-blue-600;
  box-shadow: 10px 10px 0px $color-blue-300;
}

.PreviousBtn {
  @include noStyleButton();
  color: $color-orange-400;
}
