@import 'styles/mixins';
@import 'styles/form';

.Donut {
  &__Container {
    position: relative;
    height: 100%;
    width: 11rem;
    height: 11rem;
  }
}
