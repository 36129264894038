@import 'styles/mixins';
@import 'styles/form';

.ProductType {
  @include flex();
  width: fit-content;
  height: 2rem;
  outline: 1px solid $color-blue-200;
  gap: 0.25rem;
  padding: 0.5rem;
  cursor: pointer;
  user-select: none;

  &:hover,
  &.Selected {
    background: $color-blue-100;
  }

  &.Selected {
    outline: 2px solid $color-blue-600;
  }

  .Icon {
    width: 0.8rem;
    height: auto;
  }

  &:not(.Selected) .Icon {
    @include applyFilter($color-blue-200);
  }
}

.InputCheckbox {
  display: none;
}
