@import 'styles/mixins';

.ProductTypes {
  @include flex(row, flex-start);
  gap: 1rem;
  margin-bottom: 1rem;

  &__Container {
    @include flex(column, space-between, flex-start);
    gap: 0.5rem;
  }

  &__Types {
    @include flex(row, flex-start);
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
