@import 'styles/mixins';

.Placeholder {
  @include flex();
  border: 2px dashed $color-blue-200;
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    cursor: pointer;
    max-width: 85%;
  }
  &.Error {
    border: 2px dashed $color-red-500;
  }

  &.Primary {
    span {
      color: $color-blue-400;
    }
  }
  &.Orange {
    span {
      color: $color-orange-400;
    }
  }
  &.Large {
    height: 6rem;
  }
  &.Small {
    height: 4rem;
  }
}
