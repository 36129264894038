@import 'styles/mixins';
@import 'styles/form';

.Content {
  width: 37.5rem;
  margin-bottom: 2rem;
  .Clipboard {
    @include flex(column, space-between);
    gap: 2rem;
    .Input {
      flex: 1 0 0;
      border: 2px solid #171b3a;
      box-sizing: border-box;
      width: 87%;
      border-radius: 0;
      color: $color-blue-500;
      text-overflow: ellipsis;
    }
  }
}

.Header {
  text-align: center;
  padding-top: 3rem !important;
}

:global(.p-dialog .p-dialog-header .p-dialog-title) {
  @extend .Title;
  @extend .Small;
  @extend .Regular;
}
