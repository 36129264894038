@import 'styles/mixins';

.Title {
  @include font($font-family);
  font-style: normal;
  color: $color-blue-600;
  font-weight: 400;
  display: block;

  &.ExtraLarge {
    font-size: 50px;
    line-height: 48px;
  }

  &.Large {
    font-size: 36px;
    line-height: 48px;
  }

  &.Medium {
    font-size: 28px;
    line-height: 48px;
  }

  &.Small {
    font-size: 22px;
    line-height: 28px;
  }

  &.ExtraSmall {
    font-size: 18px;
    line-height: 23px;
  }
}

.Subtitle {
  @include font($font-subtitle);
  font-style: normal;
  color: $color-blue-600;
  font-size: 20px;
  line-height: 24px;
}

.Body {
  @include font($font-subtitle);
  font-style: normal;
  color: $color-blue-600;

  &.Large {
    font-size: 16px;
    line-height: 19px;
  }
  &.Medium {
    font-size: 14px;
    line-height: 17px;
  }
  &.Small {
    font-size: 12px;
    line-height: 14px;
  }
}

.Subtitle,
.Body {
  &.Bold {
    @include font('Lato-Bold');
    font-weight: 800;
  }

  &.Regular {
    @include font($font-subtitle);
    font-weight: 400;
  }
}

.CTA {
  @include font($font-family);
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  &:hover {
    font-style: italic;
  }
}

.Blue {
  &600 {
    color: $color-blue-600;
  }
  &500 {
    color: $color-blue-500;
  }
  &400 {
    color: $color-blue-400;
  }
  &200 {
    color: $color-blue-200;
  }
  &100 {
    color: $color-blue-100;
  }
}

.Orange {
  &600 {
    color: $color-orange-600;
  }
  &400 {
    color: $color-orange-400;
  }
  &300 {
    color: $color-orange-300;
  }
}

.Red {
  &600 {
    color: $color-red-600;
  }
  &500 {
    color: $color-red-500;
  }
  &200 {
    color: $color-red-200;
  }
  &300 {
    color: $color-red-300;
  }
}

.Green {
  &600 {
    color: $color-green-600;
  }
  &500 {
    color: $color-green-500;
  }
  &300 {
    color: $color-green-300;
  }
}

.Purple {
  &600 {
    color: $color-purple-600;
  }
  &500 {
    color: $color-purple-500;
  }
  &200 {
    color: $color-purple-200;
  }
}

.Separator {
  border: 2px solid $color-blue-100;
  width: 100%;
  margin: 2.5rem 0;
  height: 0;
  &__Vertical {
    border: 2px solid $color-blue-100;
    align-self: normal;
    width: 0;
  }
}

.OrSeparator {
  @include flex(row, center, center);
  gap: 1rem;
  margin-bottom: 1rem;
  .start,
  .end {
    border-bottom: 1px solid $color-blue-200;
    flex-grow: 1;
  }
  .middle {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.White {
  color: $color-white;
}

.Card {
  @include font();
  box-shadow: $box-shadow;
  border-radius: 0%;
  border: 3px solid $color-border;
  padding: 1rem 1.5rem 1rem 1.5rem;
  width: 60%;
  margin: 3.625rem;
  text-align: center;
  color: $color-blue-600;
  .p-card-title {
    @extend .Title;
    @extend .Regular;
    @extend .Small;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  &.Dark {
    background-color: $color-blue-600;
    color: $color-white;
  }
}

.SimpleCard {
  background: $color-blue-600;
  height: max-content;
  box-sizing: border-box;
  padding: 1rem;
}

.CalendarDay {
  @include font($font-family);
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
}

.p-tooltip {
  .p-tooltip-text {
    @extend .Body;
    @extend .Regular;
    @extend .Small;
    color: $color-white;
    background: $color-black;
  }
  .p-tooltip-right .p-tooltip-arrow {
    border-right-color: $color-black;
  }
  .p-tooltip-left .p-tooltip-arrow {
    border-left-color: $color-black;
  }
  .p-tooltip-top .p-tooltip-arrow {
    border-top-color: $color-black;
  }
  .p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $color-black;
  }
}

.GlobalProgressBar {
  height: 6px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}

.white-space-preline {
  white-space: pre-line;
}
