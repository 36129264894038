@import 'styles/variables';
@import 'styles/mixins';

.Onboarding {
  width: 100%;
  min-height: 100vh;
  .Background {
    @include pageBackground(13.5rem);
  }

  &__Container {
    @include flex(column, flex-start);
    color: $color-blue-100;
    padding-top: 3.5rem;
    width: 91%;
    margin: 0 auto 0 auto;

    .Steps {
      margin: 1.5rem 0 4.75rem;
      width: 94%;
      :global(.p-steps-item) :global(.p-menuitem-link) :global(.p-steps-title) {
        font-size: 1rem;
        margin-top: 1rem;
      }
    }
  }
}
