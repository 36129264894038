@import 'styles/variables';
@import 'styles/mixins';

.Checkbox {
  all: unset;

  @include flex(row, flex-start);
  box-sizing: border-box;
  outline: 1px solid $color-blue-200;
  flex-grow: 1;
  height: 4.1rem;
  gap: 0.5rem;
  width: fit-content;
  padding: 0 0.8rem;

  &:hover,
  &.Checked,
  :hover {
    background: $color-blue-100;
    cursor: pointer;
  }

  &.Checked {
    box-sizing: border-box;
    outline: 2px solid $color-blue-600;
  }

  &.Error {
    outline: 1px solid $color-red-500;

    .Checkbox__Box {
      outline: 1px solid $color-red-500;
    }
  }

  label {
    width: 10rem;
    flex-grow: 1;
  }

  &__Box {
    @include flex();
    outline: 1px solid $color-blue-300;
    width: 0.875rem;
    height: 0.875rem;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    user-select: none;
    &.Checked {
      outline: 2px solid $color-blue-600;
      .Vector {
        display: block;
      }
    }

    .Vector {
      background: transparent;
      color: $color-blue-600;
      width: 1rem;
      height: auto;
      display: none;
    }
  }
}

.RealCheckbox {
  display: none;
  position: absolute;
}
