@import 'styles/mixins';

.Panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $color-white;
  color: $color-blue-600;
  overflow: hidden auto;
  padding: 2.5rem 2rem 0 2rem;

  &__Wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 24rem;
    transform: translateX(100%);
    transition: 0.3s ease-out;
    z-index: 10;
    overflow-y: scroll;

    &.active {
      transform: translateX(0%);
    }
  }
}

.GreyedOut {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /*dim the background*/
}
