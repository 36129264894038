@import 'styles/mixins';

.Overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  color: $color-blue-600;
  overflow: hidden auto;

  &__Wrap {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
    overflow-y: scroll;

    &.active {
      display: inherit;
    }
  }
}
