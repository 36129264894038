@import 'styles/mixins';

.EmailMenu {
  min-width: 12.625rem;
  .EmailDetails {
    padding: 1rem;
    border: 2px solid $color-blue-200;
    .Detail {
      @include flex(row, flex-start);
      gap: 0.5rem;
      img {
        @include applyFilter($color-blue-500);
        width: 20px;
        height: auto;
      }
    }

    .EmailBrief__CTA {
      margin-top: 1rem;
      width: 100%;
      padding: 0;
      &:disabled {
        .EmailBrief__CTA__Icon {
          @include applyFilter($color-grey-600);
        }
      }
      &__Icon {
        @include applyFilter($color-blue-100);
      }
    }
  }

  .Menu__CTA {
    width: 100%;
    justify-content: start;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.Separator {
  border-bottom: 2px solid $color-blue-200;
  margin: 1.5rem 0;
}
