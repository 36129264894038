@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/form';

.Card {
  @include font();
  box-shadow: $box-shadow;
  border-radius: 0%;
  border: 3px solid $color-border;
  padding: 2rem 1.5rem 1rem 1.5rem;
  color: $color-blue-600;
  width: 26rem;
  margin: 0 0 1rem 0;

  :global(.p-card-title) {
    @extend .Title;
    @extend .Regular;
    @extend .Small;
  }
  :global(.p-card-subtitle) {
    @extend .Body;
    @extend .Regular;
    @extend .Medium;
  }
}

.Register {
  @include flex();
  @include font($font-subtitle);
  margin-top: 1rem;
  gap: 0.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  .Link {
    all: unset;
    @include font('Lato-Bold');
    cursor: pointer;
    color: $color-orange-400;
    font-weight: 800;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0;
  }
}

.Layout > :last-child {
  min-height: 100vh;
}

.Container {
  @include flex(column, flex-start);
  width: 26rem;
}

.ForgotPassword {
  @include noStyleButton();
  margin-top: 1rem;
}
