@import 'styles/mixins';

.SwapOption {
  @include flex(row, space-between);
  gap: 0.25rem;
  align-items: stretch;
  margin-top: 1rem;

  .Option {
    box-sizing: border-box;
    border: 2px solid $color-blue-200;
    max-height: max-content;
    min-width: 0;
    flex: 1 1 0;
    padding: 0 0.75rem;
    .Header {
      @include flex(row, flex-start);
      gap: 0.25rem;
      padding: 0.2rem 0 0;
      .Dot {
        flex-shrink: 0;
        border-radius: 50%;
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        background: $color-purple-500;
      }
      .CampaignName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      button {
        @include noStyleButton();
        margin-left: auto;
        flex-shrink: 0;
        width: fit-content;
        height: fit-content;
        img {
          @include applyFilter($color-blue-600);
        }
      }
    }
    .Description {
      padding-bottom: 0.75rem;
    }
  }

  .SwapButton {
    @include noStyleButton();
    @include flex();
    width: 3rem;
    height: 2.5rem;
    background: $color-blue-200;
    flex-shrink: 0;
  }
}
