@import 'styles/mixins';

.CampaignLink {
  @include calendarEventColoring();
  @include flex(row, flex-start);
  height: 2.5rem;
  width: 100%;
  border: 2px solid $color-blue-200;
  padding: 0 0 0 0.5rem;
  gap: 0.25rem;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  .Link {
    @include noStyleButton();
    @include flex();
    margin-left: auto;
    height: 100%;
    width: 2rem;
    img {
      @include applyFilter($color-blue-600);
      width: 1rem;
      height: 1rem;
    }
  }
}
