@import 'styles/mixins';

.MonthlySettings {
  @include flex(column, flex-start);
  width: 100%;
  height: 100%;
  position: relative;
}

.Header {
  @include flex();
  width: 100%;
  margin-bottom: 1.75rem;

  .Close {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: auto;

    img {
      @include applyFilter($color-blue-600);
      width: 2.25rem;
      height: auto;
    }
  }
}

.EmailQuota {
  width: 100%;
  .Title {
    @include flex(row, flex-start);
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  .AmountInput {
    @include flex(row, flex-start);
    margin-bottom: 1rem;

    span {
      width: 12rem;
      margin-right: auto;
    }

    .Dropdown {
      width: 6rem;
      margin-right: 0.5rem;
    }
  }
}

.CTASection {
  margin-top: auto;
  padding-bottom: 2rem;
  width: 100%;
  .Button {
    width: 100%;
  }
}
