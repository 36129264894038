@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/form';

.DropdownContainer {
  position: relative;
  width: 50%;
  .Dropdown {
    @include font('Lato-Bold');
    max-width: 100%;
    align-self: stretch;
    border-radius: 0;
    border: 2px solid $color-blue-600;
    font-size: 14px;
    line-height: 17px;
    padding: 0;
    color: $color-blue-500;
    background: transparent;
    font-weight: 800;

    &:global(.p-dropdown:not(.p-disabled).p-focus) {
      box-shadow: none;
    }

    :global(.p-dropdown-trigger) {
      @include applyFilter($color-blue-600);
      width: 2rem;
    }

    &:global(.p-dropdown .p-dropdown-label) {
      @include font('Lato-Bold');
      @extend .Body;
      @extend .Medium;
      @extend .Regular;
      font-weight: 800;
      color: $color-blue-500;
      padding: 0.55rem 0.75rem;
      &:global(.p-placeholder) {
        @include font('Lato');
        color: $color-blue-400;
        font-weight: 400;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  label {
    @include font('Lato-Bold');
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    position: absolute;
    color: $color-blue-600;
    top: 0.875rem;
    left: 0.875rem;
  }

  &.Error {
    .Dropdown {
      border: 2px solid $color-red-500;
      &:global(.p-dropdown .p-dropdown-label) {
        color: $color-red-500;
      }
    }
    label {
      color: $color-red-500;
    }
  }

  &.Filled {
    background: $color-blue-100;
  }

  &.Disabled {
    background: $color-grey-200;
    .Dropdown {
      opacity: 1;
      border: 2px solid $color-grey-400;
      &:global(.p-dropdown .p-dropdown-label) {
        color: $color-grey-500;
      }

      :global(.p-dropdown-trigger) {
        @include applyFilter($color-grey-600);
      }
    }

    label {
      color: $color-grey-600;
    }
  }

  &.Special {
    .Dropdown {
      border: solid $color-blue-400;
      border-width: $border-underline;
      :global(.p-dropdown-trigger) {
        @include applyFilter($color-blue-400);
      }
    }
    label {
      display: none;
    }

    &.Filled .Dropdown {
      border: solid $color-blue-600;
      border-width: $border-underline;
    }

    &.Error .Dropdown {
      border: solid $color-red-500;
      border-width: $border-underline;
      &:global(.p-dropdown .p-dropdown-label) {
        color: $color-red-500;
      }
    }
  }

  &:not(.Special) {
    display: flex;
    .Dropdown {
      align-items: flex-end;
      :global(.p-dropdown-trigger) {
        padding-bottom: 0.5rem;
      }
    }
  }
}

:global(.p-dropdown-panel .p-dropdown-items .p-dropdown-item) {
  @include font('Lato');
  @extend .Body;
  @extend .Medium;
  @extend .Regular;
  color: $color-blue-400;
}
