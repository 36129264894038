@import 'styles/mixins';
@import 'styles/form';

.DatePicker {
  position: relative;
  margin-bottom: 1rem;

  &:not(.Special) {
    border: 2px solid $color-blue-600;
    height: 4rem;
    .calendarImg {
      display: none;
    }
    .chevronImg {
      @include applyFilter($color-blue-600);
    }

    .Title {
      position: absolute;
      top: 0.875rem;
      left: 0.875rem;
    }

    &.Disabled {
      background: $color-grey-200;
      border: 2px solid $color-grey-400;
      .chevronImg {
        @include applyFilter($color-grey-600);
      }
      .Title {
        color: $color-grey-600;
      }
      .Input {
        color: $color-grey-500;
      }

      :global(.p-component:disabled) {
        opacity: 1;
      }
    }
  }

  &.Filled {
    background: $color-blue-100;
  }

  .calendarImg {
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: 0.5rem;
    height: auto;
    width: 1rem;
  }
  .chevronImg {
    right: 0.25rem;
    bottom: 0.25rem;
  }
  .calendarImg,
  .chevronImg {
    @include applyFilter($color-blue-400);
    position: absolute;
    z-index: 3;
  }

  &.error {
    .calendarImg,
    .chevronImg {
      @include applyFilter($color-red-500);
    }
  }
  span {
    width: 100%;
  }
}

.Input {
  border-radius: 0;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  text-align: left;
  height: 2rem;
  border: none;
  border-bottom: 2px solid $color-blue-400;
  color: $color-blue-500;
  font-weight: bolder;
  background: none;

  &::placeholder {
    @include font('Lato');
    color: $color-blue-400;
    font-weight: 400;
  }

  &.error {
    color: $color-red-500;
    border-bottom: 2px solid $color-red-500;
    &::placeholder {
      color: $color-red-500;
    }
  }

  &:not(.Special) {
    border: none;
    height: 100%;
    padding: 1.5rem 1rem 0 0.875rem;
  }
}

:global(.p-datepicker) {
  border: 2px solid $color-blue-600;
  border-radius: 0;
  :global(table) {
    :global(td) {
      padding: 0;
      :global(> span) {
        @extend .Body;
        @extend .Regular;
        @extend .Small;
      }
    }
  }
  :global(table) {
    :global(th) {
      padding: 0;
      :global(> span) {
        @extend .Body;
        @extend .Bold;
        @extend .Small;
        color: $color-blue-400;
      }
    }
  }
}
